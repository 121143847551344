import React from 'react';

function Footer() {
  return (
    <footer style={{ backgroundColor: '#eaddcf', padding: '0.5rem 1rem', textAlign: 'left', fontSize: '0.7rem', color: '#5c3b1e' }}>
      <p style={{ margin: '0.1rem' }}>Almond AI Ltd</p>
      <p style={{ margin: '0.1rem' }}>Company Registration Number: 15962833</p>
      <p style={{ margin: '0.1rem' }}>Registered in England and Wales</p>
      <p style={{ margin: '0.1rem' }}>Registered Office Address: 3rd Floor, 86-90 Paul Street, London, England, United Kingdom, EC2A 4NE</p>
      <p style={{ margin: '0.1rem' }}>VAT Number: 476 1068 80</p>
    </footer>
  );
}

export default Footer;
