import React from 'react';

function Products() {
  return (
    <div>
      <h2>Our Products</h2>
      <p>Explore our innovative range of AI-powered tools and services, each designed to simplify your life and bring cutting-edge technology into your hands.</p>
    </div>
  );
}

export default Products;
